import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import Img from 'gatsby-image'

import styles from './slantedCard.module.css'

const SlantedCard = ({cardClass, backgroundImage, title, url}) => (
    <Link to={url} className={styles.card + ' ' + cardClass}>
        <div className={styles.wrapper}>
          <Img fluid={backgroundImage.childImageSharp.fluid} alt="background image" className={styles.cardImage}/>
          <h2 className={styles.cardHeader}>{title}</h2>
        </div>
    </Link>
)

export default SlantedCard
