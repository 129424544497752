import React from 'react'
import {graphql, Link} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Hero from '../components/homeHero'
import AboutSection from '../components/aboutSection'
import ProjectGrid from '../components/projectGrid'
import ClientsSection from '../components/clientsSection'

import SEO from '../components/seo'
import Layout from '../containers/layout'
import heroBackground from "../assets/images/hero-background.jpg"
import styles from '../styles/globalStyles.css'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    },
    aboutImage: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    heroImageBlake: file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardOneBg: file(relativePath: { eq: "professional-life-card-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardTwoBg: file(relativePath: { eq: "personal-life-card-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    clientIcons: file(relativePath: { eq: "client-icons.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1140, height: 93) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    projects: allSanityProject(
      limit: 4
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          mainImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid_withWebp
              },
              fixed(width: 400) {
                ...GatsbySanityImageFixed_withWebp
              }
            }
          }
          thumbImage {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid_withWebp
              },
              fixed(width: 400) {
                ...GatsbySanityImageFixed_withWebp
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const cardOneBg = (data || {}).cardOneBg
  const aboutImage = (data || {}).aboutImage
  const heroImageBlake = (data || {}).heroImageBlake
  const cardTwoBg = (data || {}).cardTwoBg
  const clientIcons = (data || {}).clientIcons
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Hero heroBackground={{heroBackground}} heroImageBlake={{heroImageBlake}} />
        <AboutSection
          aboutText={"<p>Born and raised in Georgia, I got my start coding professionally at the age of seventeen working with dozens of small companies across the southeast. In the nearly two decades since, I’ve helped hundreds of clients and creative agencies of all sizes grow and expand their businesses. My love of sunshine and adventure along with the allure of the west coast startup culture made the move from Atlanta to Los Angeles an easy decision. Amazing weather, delicious street tacos and meeting my beautiful wife all led me to make LA my permanent home.</p>"}
          aboutImage={aboutImage}
          title={'Hi, welcome to my portfolio.'}
          cardOneBg={cardOneBg}
          cardTwoBg={cardTwoBg}
        />
        {projectNodes && (
          <div className="recent-work-section">
            <h2 className="sectionTitle"><u>Work</u> I've Done</h2>
            <ProjectGrid nodes={projectNodes} />
            <div className={'viewAllLink'}>
              <Link to='/work/'>View All Projects >></Link>
            </div>
          </div>
        )}
        <ClientsSection clientsText={'<u>Brands</u> I\'ve worked with.'} clientIcons={clientIcons}/>
      </Container>
    </Layout>
  )
}

export default IndexPage
