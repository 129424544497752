import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import SlantedCard from './slantedCard'
import Img from "gatsby-image/withIEPolyfill"

// import resumeCardBackground from '../assets/images/professional-life-card-bg.jpg'
// import myStoryCardBackground from '../assets/images/personal-life-card-bg.jpg'

import styles from './aboutSection.module.css'

const AboutSection = (props) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
        <div className={styles.aboutMeImageWrap}>
          <Img fluid={props.aboutImage ? props.aboutImage.childImageSharp.fluid : {}} alt="pic of my family" className={styles.aboutMeImage} />
        </div>
        <div className={styles.aboutContent}>
            <div className={styles.aboutContentWrapper}>
              <h2 dangerouslySetInnerHTML={{__html: props.title}} />
              <div dangerouslySetInnerHTML={{__html: props.aboutText}} />
            </div>
        </div>
    </div>
    <div className={styles.personalProfessionalSection}>
      <SlantedCard title="My Story" backgroundImage={props.cardTwoBg} cardClass="left" url="/about" />
      <SlantedCard title="Résumé" backgroundImage={props.cardOneBg} cardClass="right" url="/professional" />
    </div>
  </div>
)

export default AboutSection
