import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import Img from 'gatsby-image'

import styles from './clients.module.css'

const Clients = ({clientsText, clientIcons}) => (
    <div className={styles.root + ' clients-wrapper'}>
        <div className={styles.wrapper}>
            <h2 className="sectionTitle" dangerouslySetInnerHTML={{__html: clientsText}} />
            <Img fixed={clientIcons.childImageSharp.fixed} alt="Client Icons image" className={styles.clientIcons}/>
        </div>
    </div>
)

export default Clients
