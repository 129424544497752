import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import { ParallaxBanner } from 'react-scroll-parallax';
import tree from '../assets/images/tree.png';
import Img from "gatsby-image/withIEPolyfill"

import styles from './hero.module.css'

const Hero = (props) => (
  <ParallaxBanner
      className={styles.root + ' hero-wrapper'}
      layers={[
          {
            image: props.heroBackground.heroBackground,
            amount: 0.2,
          },
          {
            image: tree,
            amount: 0.08,
            props: {
              id: 'tree',
              style: {
                backgroundSize: '25% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '100% bottom',
              }
            },
          },
      ]}
      style={{
          height: 'calc(100vw / 1.8)'
      }}>
      <Img fluid={props.heroImageBlake.heroImageBlake ? props.heroImageBlake.heroImageBlake.childImageSharp.fluid : {}} className={styles.myImage} alt="Image of Blake looking out at mountains" />
      <div className="text">
      <h1 className={styles.heroCopy}>Hi, I’m Blake Moore. <br />I’m A
        <div className={styles.slidingVertical}>
           <span>Javascript Dev.</span>
           <span>Problem Solver.</span>
           <span>Family Man.</span>
           <span>Digital Nomad.</span>
           <span>Car Guy.</span>
        </div>
       </h1>
    </div>
  </ParallaxBanner>
)

export default Hero
